import React from "react"
import Helmet from "react-helmet";
import { graphql } from "gatsby"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, fields } = markdownRemark
  return (
    <>
      <Helmet>
        <script async src="//www.instagram.com/embed.js"></script>
      </Helmet>
      <header className="masthead"
        style={{ backgroundImage: `url(${fields.featuredImage})` }}>
        <div className="container text-white">
          <h1>{frontmatter.title}</h1>
          <h2>{frontmatter.date}</h2>
          <a className="text-white" href="../"> &lt;-- Back </a>
        </div>
      </header>
      <div className="container">
        <div className="row">
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />

          </div>
        </div>
      </div>
    </>

  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
          slug
          featuredImage
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
      }
    }
  }
`